import { Home } from "./Home";
import { useState } from "react";
import { AppLayout } from "@cloudscape-design/components";

function HomePage() {
  const [toolsOpen, setToolsOpen] = useState(false);

  return (
    <AppLayout
      content={<Home />}
      toolsHide={true}
      navigationHide={true}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      contentType="default"
    />
  );
}

export default HomePage;
